import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
			PlayFooty
			</title>
			<meta property={"og:image"} content={"none"} />
			<link rel={"shortcut icon"} href={"https://inkzstar-place.com/img/8133849.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://inkzstar-place.com/img/8133849.png"} />
			<meta name={"msapplication-TileImage"} content={"https://inkzstar-place.com/img/8133849.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Hakkımızda
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				PlayFooty ve sunduğumuz hizmetler hakkında daha fazla bilgi edinin. Birinci sınıf futbol sahaları kiralama hizmetlerinden ek tesislere ve hizmetlere kadar, futbol ihtiyaçlarınız için neden en iyi tercih olduğumuzu öğrenin.
			</Text>
		</Section>
		<Section padding="0 0 0 0" quarkly-title="Statistics-7">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Kimiz Biz
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						PlayFooty, müşteri memnuniyeti ve olağanüstü hizmetlere özel önem vererek en yüksek kalitede futbol sahaları kiralama hizmetleri sunmaktadır. Misyonumuz, rastgele bir oyuncu, rekabetçi bir takım veya etkinlik organizatörü olsanız da, birinci sınıf bir futbol deneyimi sunmaktır.
						<br />
						<br />
						Ekibimiz futbola tutkuyla bağlıdır ve en yüksek standartlara uygun bir tesis sunmaya kendini adamıştır. Sahamızın her rezervasyon için mükemmel durumda olmasını sağlıyoruz ve deneyiminizi olabildiğince keyifli hale getirmek için bir dizi ek hizmet sunuyoruz.
					</Text>
					<Box
						min-width="100px"
						min-height="100px"
						margin="60px 0px 0px 0px"
						display="grid"
						grid-template-columns="repeat(2, 1fr)"
						grid-template-rows="repeat(2, 1fr)"
						lg-margin="50px 0px 0px 0px"
						md-margin="40px 0px 0px 0px"
						sm-grid-template-columns="1fr"
						sm-grid-template-rows="auto"
					>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 36px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								500+
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Daimi Oyuncular
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 36px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								8+
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Profesyonel Hakemler
							</Text>
						</Box>
						<Box
							min-width="100px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 30px 0px"
							min-height="80px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 36px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								40+
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Resmi Maçlar
							</Text>
						</Box>
						<Box
							min-width="100px"
							min-height="80px"
							padding="0px 0px 0 15px"
							border-width="0 0 0 1px"
							border-style="solid"
							border-color="#d4d5db"
							margin="0px 0px 35px 0px"
						>
							<Text margin="0px 0px 0px 0px" font="normal 600 36px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
								2000+
							</Text>
							<Text margin="15px 0px 0px 0px" color="#75767d" font="300 18px --fontFamily-sansHelvetica">
								Atılan Goller
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://inkzstar-place.com/img/8.jpg"
						display="block"
						width="auto"
						height="750px"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override
				slot="SectionContent"
				flex-direction="row"
				sm-min-width="280px"
				align-self="auto"
				justify-content="flex-start"
			/>
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://inkzstar-place.com/img/9.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Sahamız
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					Futbol sahamız hem oyuncuların hem de seyircilerin çıkarları gözetilerek tasarlanmıştır. Üstün kaliteli yüzey, mükemmel bir tutuş ve dayanıklılık sağlar. Sahanın boyutları, hem antrenmanlar hem de yarışmalar için uygun hale getirilmiş resmi standartlara uygundur.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					Tesiste geniş soyunma odaları, temiz duşlar ve rahat dinlenme alanları gibi modern imkanlar bulunmaktadır. Ayrıca oyundan önce, sırasında ve sonrasında bağlantıda kalabilmeniz için ücretsiz Wi-Fi sunuyoruz.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="CTA/Lead/Form-6">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 84px"
				margin="0px 0px 40px 0px"
				lg-grid-template-columns="1fr"
				sm-grid-gap="36px 24px"
			>
				<Box min-width="100px" min-height="100px" lg-padding="0px 150px 0px 0px" md-padding="0px 0 0px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 700 42px/1.2 --fontFamily-sans">
						Sahanızı Bugün Rezerve Edin!
					</Text>
					<Text margin="0px 0px 10px 0px" font="normal 300 22px/1.5 --fontFamily-sansHelvetica">
						PlayFooty'de mükemmelliğe olan bağlılığımızla gurur duyuyoruz. Tesisimiz, en iyi oyun koşullarını sağlamak için düzenli olarak bakım yapılmaktadır. Müşterilerimize üstün bir deneyim sağlamak için modern ekipmanlara ve olanaklara yatırım yapıyoruz. 
						Personelimiz iyi eğitimli ve birinci sınıf hizmet sunmaya kendini adamıştır. Müşterilerimizin geri bildirimlerini dinliyor ve ihtiyaçlarına uygun tekliflerimizi sürekli geliştiriyoruz. Memnuniyetiniz bizim önceliğimizdir ve her ziyareti unutulmaz kılmaya çalışıyoruz.
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						PlayFooty ile en iyi futbol sahası kiralama hizmetlerinden ve ek hizmetlerden yararlanın. Fırsatı kaçırmayın - sahanızı şimdi rezerve edin ve birinci sınıf futbol deneyiminin tadını çıkarın.
					</Text>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-primary"
						hover-background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Rezerve Et
					</Link>
				</Box>
				<Image
					src="https://inkzstar-place.com/img/10.jpg"
					display="block"
					width="100%"
					height="100%"
					object-fit="cover"
					border-radius="25px"
					margin="0px 0px 0px 0"
					lg-order="-1"
					lg-height="500px"
					md-height="400px"
				/>
			</Box>
		</Section>
		<Components.Footer />
	</Theme>;
});